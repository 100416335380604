import { createSharedComposable, useEventBus } from '@vueuse/core'
import { useTronAccount } from './useTronAccount'
import { useTronState } from './useTron'
import { useInspectTronChaubId } from './useInspectTronChaubId'

export { useTronAccountCurrent }

const useTronAccountCurrent = createSharedComposable(() => {
  const { account: current } = useTronState()
  const isConnected = computed(() => {
    return !!current.address && !!current.chainId
  })
  const accountKey = computed(() => current.address + current.chainId)
  const bus = useEventBus<string>('SWITCH-WALLET')

  watch(accountKey, (val: string) => {
    bus.emit(val)
  })

  useInspectTronChaubId()

  return {
    isConnected,
    accountKey,
    ...useTronAccount(toRef(current, 'address'), toRef(current, 'chainId')),
  }
})
