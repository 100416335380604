import { useSessionStorage, useUrlSearchParams } from '@vueuse/core'

export function isBitpie() {
  const params = useUrlSearchParams('history')
  const cache = useSessionStorage('IS_BITPIE', 0)

  watchEffect(() => {
    if (params.utm_source && params.utm_source === 'bitpie')
      cache.value = 1
  })
}
