import { Buffer } from 'buffer'
import { TronChainId } from '../constants'
import { throwErrorType, tronErrors } from '../tronError'
import type { Token } from './Token'
import { env } from '~/utils'

export { tokens }

class TRX implements Token {
  public readonly symbol = 'TRX'

  public constructor(public contracts: Record<TronChainId, string>) {}

  public async fetchBalance(address: string, chainId: string) {
    const tronWeb = (window as any).tronWeb
    const balance = await tronWeb?.trx.getBalance(address)
    return balance ? Number(balance) / 10 ** 6 : 0
  }

  public async transfer(params: {
    fromAddress: string
    toAddress: string
    chainId: string
    amount: string
    bandwidthSurplus: number
    trxSurplus: number
    energyTrxSurplus: number
    broadcast: boolean
  }) {
    const tronWeb = (window as any).tronWeb
    const { fromAddress, toAddress, amount, broadcast, bandwidthSurplus, energyTrxSurplus } = params

    try {
      const tx = await tronWeb.transactionBuilder.sendTrx(
        toAddress,
        tronWeb.toSun(amount),
        fromAddress,
      )
      // 本次交易需消耗的带宽费用
      const transactionByteLength = Buffer.from(tx.raw_data_hex, 'hex').byteLength + 69 + 65
      // 本订单所消耗带宽 * 2 - 账号剩余带宽 <= 0 说明账号是足够支付本订单所需要的trx
      const bandwidthQuantity = transactionByteLength * 2 - bandwidthSurplus <= 0
      // trx
      const trxQuantity = energyTrxSurplus - Number(amount) < Number((transactionByteLength * 1000) / 1000000)

      if (!bandwidthQuantity && trxQuantity)
        throw tronErrors.RESOURCE_INSUFFICIENT_ERROR()

      const signedTx = await tronWeb?.trx.sign(tx)

      if (broadcast) {
        const broastTx = await tronWeb?.trx.sendRawTransaction(signedTx)
        return broastTx
      }

      return signedTx
    }
    catch (err) {
      throwErrorType(err)
    }
  }
}

class USDT implements Token {
  public readonly symbol = 'USDT'

  public constructor(public contracts: Record<TronChainId, string>) {}

  public async fetchBalance(address: string, chainId: string) {
    const tronWeb = (window as any).tronWeb
    const contractAddress = this.contracts[chainId as TronChainId]

    const contract = await tronWeb?.contract().at(contractAddress)
    const balance = await contract!.balanceOf(address).call()
    return Number(balance) / 10 ** 6
  }

  public async transfer(params: {
    fromAddress: string
    toAddress: string
    chainId: string
    amount: string
    bandwidthSurplus: number
    trxSurplus: number
    energyTrxSurplus: number
    broadcast: boolean
  }) {
    const tronWeb = (window as any).tronWeb
    const { fromAddress, toAddress, amount, chainId, broadcast, energyTrxSurplus, bandwidthSurplus } = params
    const contractAddress = this.contracts[chainId as TronChainId]
    const parameter = [
      { type: 'address', value: toAddress },
      { type: 'uint256', value: tronWeb?.toSun(amount) },
    ]

    try {
      const { transaction } = await tronWeb.transactionBuilder.triggerSmartContract(
        contractAddress,
        'transfer(address,uint256)',
        {},
        parameter,
        tronWeb.address.toHex(fromAddress),
      )
      // 本次交易需消耗的带宽费用
      const transactionByteLength = Buffer.from(transaction.raw_data_hex, 'hex').byteLength + 69 + 65
      // 本订单所消耗带宽 * 2 - 账号剩余带宽 <= 0 足够支付本订单所需要的trx
      const bandwidthQuantity = transactionByteLength * 2 - bandwidthSurplus <= 0
      // trx
      const trxQuantity = energyTrxSurplus < Number((transactionByteLength * 1000) / 1000000)

      if (!bandwidthQuantity && trxQuantity)
        throw tronErrors.RESOURCE_INSUFFICIENT_ERROR()

      const signedTx = await tronWeb?.trx.sign(transaction)

      if (broadcast) {
        const broastTx = await tronWeb?.trx.sendRawTransaction(signedTx)
        return broastTx
      }

      return signedTx
    }
    catch (err) {
      throwErrorType(err)
    }
  }
}
const USDT_CONTRACT = env.prod ? 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t' : 'TMBcysKqzGy82y8Y8awJGh883SNkujrQnk'

// 币种列表
const tokens = [
  new TRX({
    [TronChainId.Main]: '',
    [TronChainId.Nile]: '',
  }),
  new USDT({
    [TronChainId.Main]: USDT_CONTRACT,
    [TronChainId.Nile]: USDT_CONTRACT,
  }),
]
