import { createRequest } from './main'

const serviceName = 'main.api'

/**
 * 账号激活检测
 */
export const accountCheckActivation = createRequest<
  {
    address?: string
  },
  { code: number; data: { address: string; is_activated: boolean }; msg: string; request_id: string }
>('accountCheckActivation', ({ address }) => ({
  url: `/v1/account/check_activation`,
  method: 'GET',
  params: {
    address,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 获取钱包账号信息(原结构)
 */
export const accountGetDetail = createRequest<
  {
    address: string
  },
  { code: number; data: IBoChangQianBaoJiBenXinXi; msg: string; request_id: string }
>('accountGetDetail', ({ address }) => ({
  url: `/v1/account/get_detail`,
  method: 'GET',
  params: {
    address,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 获取钱包账号信息
 */
export const accountGetDetailV2 = createRequest<
  {
    address: string
  },
  { code: number; data: IBoChangQianBaoJiBenXinXi; msg: string; request_id: string }
>('accountGetDetailV2', ({ address }) => ({
  url: `/v1/account/get_detail_v2`,
  method: 'GET',
  params: {
    address,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 获取钱包账号可委托资源
 */
export const acountGetCanDelegatedMaxSize = createRequest<
  {
    address?: string
    network?: string
    type?: number
  },
  { code: number; data: { max_size: number; resource_value: number }; msg: string; request_id: string }
>('acountGetCanDelegatedMaxSize', ({ address, network, type }) => ({
  url: `/v1/account/getcandelegatedmaxsize`,
  method: 'GET',
  params: {
    address,
    network,
    type,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 活动配置
 */
export const activityConfig = createRequest<
  {
    trx_address: string
  },
  {
    code: number
    data: { available: number; limit: number; receive_limit: number; received: number; status: number }
    msg: string
    request_id: string
  }
>('activityConfig', ({ trx_address }) => ({
  url: `/v1/activity/config`,
  method: 'GET',
  params: {
    trx_address,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 领取免费能量创建订单
 */
export const activityCreateFreeOrder = createRequest<
  {
    requestBody?: { pay_address: string; receive_address?: string }
  },
  { code: number; data: null | IZuYongDingDanXiangQing; msg: string; request_id: string }
>('activityCreateFreeOrder', ({ requestBody }) => ({
  url: `/v1/activity/create_free_order`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 免费活动领取记录
 */
export const activityReceiveLog = createRequest<
  {
    page?: number
    page_size?: number
  },
  { code: number; data: null | ILastTradeDetail[]; msg: string; pagination: IPagination; request_id: string }
>('activityReceiveLog', ({ page, page_size }) => ({
  url: `/v1/activity/receive_log`,
  method: 'GET',
  params: {
    page,
    page_size,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 代理申请
 */
export const agentApply = createRequest<
  {
    requestBody?: { channel: string; contacts: string; cooperation_content?: string }
  },
  {}
>('agentApply', ({ requestBody }) => ({
  url: `/v1/agent/apply`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 查看子订单
 */
export const agentChildOrderList = createRequest<
  {
    order_parent_no: string
    page?: number
    page_size?: number
  },
  { code: number; data: null | IZuYongDingDanXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>('agentChildOrderList', ({ page, page_size, order_parent_no }) => ({
  url: `/v1/agent/order_child`,
  method: 'GET',
  params: {
    page,
    page_size,
    order_parent_no,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 订单详情
 */
export const agentOrderDetail = createRequest<
  {
    order_no?: string
  },
  { code: number; data: IZuYongDingDanXiangQing; msg: string; request_id: string }
>('agentOrderDetail', ({ order_no }) => ({
  url: `/v1/agent/order_detail`,
  method: 'GET',
  params: {
    order_no,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 订单列表导出Excel
 */
export const agentOrderExport = createRequest<
  {
    end_time?: string
    start_time?: string
  },
  { code: number; data: null | {}[]; msg: string; pagination: IPagination; request_id: string }
>('agentOrderExport', ({ start_time, end_time }) => ({
  url: `/v1/agent/order_export`,
  method: 'GET',
  params: {
    start_time,
    end_time,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 查看代理订单
 */
export const agentOrderList = createRequest<
  {
    action?: number
    end_time?: string
    from?: string
    one_page?: number
    order_no?: string
    page?: number
    page_size?: number
    scene?: number
    start_time?: string
  },
  { code: number; data: null | IZuYongDingDanXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>('agentOrderList', ({ scene, order_no, action, start_time, end_time, one_page, page, page_size, from }) => ({
  url: `/v1/agent/order`,
  method: 'GET',
  params: {
    scene,
    order_no,
    action,
    start_time,
    end_time,
    one_page,
    page,
    page_size,
    from,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 统计概览
 */
export const agentStatistics = createRequest<
  undefined,
  { code: number; data: IDaiLiGaiLan; msg: string; request_id: string }
>('agentStatistics', () => ({ url: `/v1/agent/statistics`, method: 'GET', headers: { 'Service-Name': serviceName } }))

/**
 * 编辑代理详情
 */
export const agentUpdate = createRequest<
  {
    requestBody?: {
      agent_id: number
      allow_customize_price: number
      announcement_cn: string
      announcement_en: string
      announcement_ru: string
      buy_increase_sun: number
      domain: string
      show_last_trades: number
      site_name: string
      telegram_bot_enable: number
      telegram_bot_name: string
      telegram_bot_token: string
      telegram_bot_username: string
      telegram_bot_webapp_name: string
      telegram_cs_username: string
    }
  },
  {}
>('agentUpdate', ({ requestBody }) => ({
  url: `/v1/agent/update`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 编辑机器人设置
 */
export const agentUpdateBot = createRequest<
  {
    requestBody?: {
      agent_id: number
      buy_increase_sun: number
      telegram_bot_enable: number
      telegram_bot_name: string
      telegram_bot_token: string
      telegram_bot_username: string
      telegram_bot_webapp_name: string
    }
  },
  {}
>('agentUpdateBot', ({ requestBody }) => ({
  url: `/v1/agent/update_bot`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 编辑网站设置
 */
export const agentUpdateSite = createRequest<
  {
    requestBody?: {
      agent_id: number
      allow_customize_price: number
      announcement_cn: string
      announcement_en: string
      announcement_ru: string
      buy_increase_sun: number
      domain: string
      show_last_trades: number
      site_name: string
      telegram_cs_username: string
    }
  },
  {}
>('agentUpdateSite', ({ requestBody }) => ({
  url: `/v1/agent/update_site`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 在代理下注册的用户详情
 */
export const agentUserDetail = createRequest<
  {
    unique_id?: string
  },
  { code: number; data: IUserLiteDetailResp; msg: string; request_id: string }
>('agentUserDetail', ({ unique_id }) => ({
  url: `/v1/agent/user_detail`,
  method: 'GET',
  params: {
    unique_id,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 在代理下注册的用户列表
 */
export const agentUserList = createRequest<
  {
    order?: string
    page?: number
    page_size?: number
    sort?: string
  },
  {
    code: number
    data: null | IUserLiteDetail[]
    msg: string
    pagination: { has_more: boolean; page: number; page_size: number; total: number }
    request_id: string
  }
>('agentUserList', ({ page, page_size, sort, order }) => ({
  url: `/v1/agent/user_list`,
  method: 'GET',
  params: {
    page,
    page_size,
    sort,
    order,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 获取广告
 */
export const apiAdvertGet = createRequest<undefined, {}>('apiAdvertGet', () => ({
  url: `/v1/advert/get`,
  method: 'GET',
  headers: { 'Service-Name': serviceName },
}))

/**
 * API详情(查看key)
 */
export const apiAppDetail = createRequest<
  {
    requestBody?: { id: number; password?: string; security_code?: string }
  },
  { code: number; data: null | IApiXiangQing; msg: string; pagination?: null; request_id: string }
>('apiAppDetail', ({ requestBody }) => ({
  url: `/v1/api/detail`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 获取二维码URL
 */
export const apiAuthenticatorQrCode = createRequest<
  undefined,
  { code: number; data: { qr_code: string; secret_key: string }; msg: string; request_id: string }
>('apiAuthenticatorQrCode', () => ({
  url: `/v1/authenticator/qrcode`,
  method: 'GET',
  headers: { 'Service-Name': serviceName },
}))

/**
 * 验证开关
 */
export const apiAuthenticatorSwitch = createRequest<
  {
    requestBody?: { enable_authenticator: number; security_code: string }
  },
  { code: number; data: null; msg: string; request_id: string }
>('apiAuthenticatorSwitch', ({ requestBody }) => ({
  url: `/v1/authenticator/switch`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 验证安全码
 */
export const apiAuthenticatorVerifyCode = createRequest<
  {
    requestBody?: { security_code: string }
  },
  { code: number; data: null; msg: string; request_id: string }
>('apiAuthenticatorVerifyCode', ({ requestBody }) => ({
  url: `/v1/authenticator/verify`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 购买TRX
 */
export const apiExchangeBuyTrx = createRequest<
  {
    requestBody?: { order_no: string; pay_tx_id: string; signed_txn: ISignedTransaction }
  },
  {}
>('apiExchangeBuyTrx', ({ requestBody }) => ({
  url: `/v1/exchange/buy_trx`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 购买TRX预请求
 */
export const apiExchangePreBuyTrx = createRequest<
  {
    requestBody?: { pay_address: string; receive_address: string; trx_amount: number }
  },
  { code: number; data?: IBuyTrxTransactionResp | null; msg: string; pagination?: null; request_id: string }
>('apiExchangePreBuyTrx', ({ requestBody }) => ({
  url: `/v1/exchange/pre_buy_trx`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 查询购买TRX信息
 */
export const apiExchangeQueryBuyTrx = createRequest<
  {
    requestBody?: { pay_address: string; receive_address: string; trx_amount: number }
  },
  { code: number; data?: IBuyTrxTransactionResp | null; msg: string; pagination?: null; request_id: string }
>('apiExchangeQueryBuyTrx', ({ requestBody }) => ({
  url: `/v1/exchange/query_buy_trx`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 添加导出订单任务
 */
export const apiExportOrderExport = createRequest<
  {
    requestBody?: {
      end_time: string
      filter?: number
      lang: string
      receive_address?: string
      resource_type: number
      start_time: string
      timezone_offset?: number
    }
    uid?: number
  },
  { code: number; data?: null | IExportTaskDetail; msg: string; request_id: string }
>('apiExportOrderExport', ({ uid, requestBody }) => ({
  url: `/v1/export/order_export`,
  method: 'POST',
  data: requestBody,
  params: {
    uid,
  },
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 查询导出任务接口
 */
export const apiExportQuery = createRequest<
  {
    task_id?: number
  },
  { code: number; data: null | IExportTaskDetail; msg: string; pagination?: null; request_id: string }
>('apiExportQuery', ({ task_id }) => ({
  url: `/v1/export/query`,
  method: 'GET',
  params: {
    task_id,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 添加API
 */
export const apiInsert = createRequest<
  {
    requestBody?: {
      ip_whitelist: string
      name: string
      password?: string
      security_code?: string
      ua_whitelist?: string
    }
  },
  { code: number; data: IApiXiangQing; msg: string; request_id: string }
>('apiInsert', ({ requestBody }) => ({
  url: `/v1/api/insert`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 删除apiKey
 */
export const apiKeyDel = createRequest<
  {
    requestBody?: { id: number; name?: string; password?: string; security_code?: string }
  },
  { code: number; data: string; msg: string; request_id: string }
>('apiKeyDel', ({ requestBody }) => ({
  url: `/v1/api/del`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * API列表
 */
export const apiList = createRequest<
  undefined,
  { code: number; data: null | IApiXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>('apiList', () => ({ url: `/v1/api/list`, method: 'GET', headers: { 'Service-Name': serviceName } }))

/**
 * 资讯分类
 */
export const apiNewsCategory = createRequest<
  undefined,
  { code: number; data: null | INewsMetaDetail[]; msg: string; request_id: string }
>('apiNewsCategory', () => ({
  url: `/blog/v1/news/category`,
  method: 'GET',
  headers: { 'Service-Name': serviceName },
}))

/**
 * 资讯详情
 */
export const apiNewsDetail = createRequest<
  {
    news_id: number
  },
  { code: number; data: null | INewsDetail; msg: string; request_id: string }
>('apiNewsDetail', ({ news_id }) => ({
  url: `/blog/v1/news/detail`,
  method: 'GET',
  params: {
    news_id,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 资讯列表
 */
export const apiNewsList = createRequest<
  {
    alias?: string
    keyword?: string
    mid?: number
    page?: number
    page_size?: number
  },
  { code: number; data: null | INewsDetail[]; msg: string; pagination: IPagination; request_id: string }
>('apiNewsList', ({ mid, alias, keyword, page, page_size }) => ({
  url: `/blog/v1/news/list`,
  method: 'GET',
  params: {
    mid,
    alias,
    keyword,
    page,
    page_size,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 相关资讯列表
 */
export const apiNewsRelated = createRequest<
  {
    news_id: number
    page_size?: number
  },
  { code: number; data: null | INewsDetail[]; msg: string; request_id: string }
>('apiNewsRelated', ({ news_id, page_size }) => ({
  url: `/blog/v1/news/related`,
  method: 'GET',
  params: {
    news_id,
    page_size,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * API后台管理手工取消
 */
export const apiOrderCancel = createRequest<
  {
    requestBody?: { order_no: string; pay_address?: string; pay_tx_id?: string }
  },
  { code: number; data: IZuYongDingDanXiangQing; msg: string; request_id: string }
>('apiOrderCancel', ({ requestBody }) => ({
  url: `/v1/api/order_cancel`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 批量下单
 */
export const apiOrderCreateBatchOrder = createRequest<
  {
    requestBody?: {
      pay_amount: number
      receivers: string
      rent_duration?: number
      rent_time_second?: number
      rent_time_unit?: string
      resource_type?: number
      resource_value: number
    }
  },
  { code: number; data: IZuYongDingDanXiangQing; msg: string; request_id: string }
>('apiOrderCreateBatchOrder', ({ requestBody }) => ({
  url: `/v1/order/create_batch_order`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 余额下单
 */
export const apiOrderCreateOrderByBalance = createRequest<
  {
    requestBody?: {
      order_type: number
      pay_amount: number
      price_in_sun?: number
      receive_address: string
      rent_duration?: number
      rent_time_second?: number
      rent_time_unit?: string
      resource_type?: number
      resource_value: number
      wait_second: number
    }
  },
  { code: number; data: IZuYongDingDanXiangQing; msg: string; request_id: string }
>('apiOrderCreateOrderByBalance', ({ requestBody }) => ({
  url: `/v1/order/create_order_by_balance`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * API后台管理手工下单
 */
export const apiOrderSubmit = createRequest<
  {
    requestBody?: {
      pay_symbol?: string
      receive_address: string
      rent_duration: number
      rent_time_unit?: string
      resource_type?: number
      resource_value: number
      wait_second: number
    }
  },
  { code: number; data: IZuYongDingDanXiangQing; msg: string; request_id: string }
>('apiOrderSubmit', ({ requestBody }) => ({
  url: `/v1/api/order_submit`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 重置apikey
 */
export const apiResetKey = createRequest<
  {
    requestBody?: { id: number; password?: string; security_code?: string }
  },
  { code: number; data: IApiXiangQing; msg: string; request_id: string }
>('apiResetKey', ({ requestBody }) => ({
  url: `/v1/api/resetkey`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 出售订单曲线图表
 */
export const apiSellerOrderChart = createRequest<
  undefined,
  {
    code: number
    data: { amount?: number[]; axis_data?: string[]; count?: number[]; name: string; timeline: string }
    msg: string
    pagination: null
    request_id: string
  }
>('apiSellerOrderChart', () => ({
  url: `/v1/seller/order_chart`,
  method: 'GET',
  headers: { 'Service-Name': serviceName },
}))

/**
 * 更新API
 */
export const apiUpdate = createRequest<
  {
    requestBody?: {
      id: number
      ip_whitelist: string
      name: string
      password?: string
      security_code?: string
      ua_whitelist?: string
    }
  },
  { code: number; data: IApiXiangQing; msg: string; request_id: string }
>('apiUpdate', ({ requestBody }) => ({
  url: `/v1/api/update`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * API用户可用数据
 */
export const apiUsableRent = createRequest<
  {
    amount: number
  },
  { code: number; data: IUsableRent; msg: string; request_id: string }
>('apiUsableRent', ({ amount }) => ({
  url: `/v1/api/usable_rent`,
  method: 'GET',
  params: {
    amount,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 踢人下线
 */
export const apiUserSessionsKick = createRequest<
  {
    requestBody?: { password?: string; security_code?: string; session_ids: string[] }
  },
  { code: number; data: { rows_affected: number }; msg: string; request_id: string }
>('apiUserSessionsKick', ({ requestBody }) => ({
  url: `/v1/user/sessions/kick`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 会话列表
 */
export const apiUserSessionsList = createRequest<
  {
    page?: number
    page_size?: number
  },
  { code: number; data: null | IUserSessionDetail[]; msg: string; pagination?: IPagination; request_id: string }
>('apiUserSessionsList', ({ page, page_size }) => ({
  url: `/v1/user/sessions/list`,
  method: 'GET',
  params: {
    page,
    page_size,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 文章详情
 */
export const articleDetail = createRequest<
  {
    id?: number
  },
  { code: number; data: IWenZhang; msg: string; request_id: string }
>('articleDetail', ({ id }) => ({
  url: `/v1/article/detail`,
  method: 'GET',
  params: {
    id,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 文章列表
 */
export const articleList = createRequest<
  {
    is_popup?: number
    page?: number
    page_size?: number
    pos?: number
    type?: number
  },
  { code: number; data: null | IWenZhang[]; msg: string; pagination: IPagination; request_id: string }
>('articleList', ({ page, page_size, type, pos, is_popup }) => ({
  url: `/v1/article/list`,
  method: 'GET',
  params: {
    page,
    page_size,
    type,
    pos,
    is_popup,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 忘记密码发送邮件
 */
export const authForgetPassword = createRequest<
  {
    requestBody?: { captcha: string; captcha_id: string; email: string }
  },
  IChengGong
>('authForgetPassword', ({ requestBody }) => ({
  url: `/v1/auth/forget_password`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 谷歌授权登陆
 */
export const authGoogleOAuthLogin = createRequest<
  {
    requestBody?: { code: string; redirect_uri: string }
  },
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('authGoogleOAuthLogin', ({ requestBody }) => ({
  url: `/v1/auth/google_oauth_login`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 谷歌授权注册
 */
export const authGoogleOAuthRegister = createRequest<
  {
    requestBody?: { code: string; invite_code?: string; redirect_uri: string }
  },
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('authGoogleOAuthRegister', ({ requestBody }) => ({
  url: `/v1/auth/google_oauth_register`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 谷歌授权注册或登陆整合
 */
export const authGoogleOAuthSignIn = createRequest<
  {
    requestBody?: { code: string; invite_code?: string; redirect_uri: string }
  },
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('authGoogleOAuthSignIn', ({ requestBody }) => ({
  url: `/v1/auth/google_oauth_sign_in`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 谷歌一键登录
 */
export const authGoogleQuickLogin = createRequest<
  {
    requestBody?: { credential: string }
  },
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('authGoogleQuickLogin', ({ requestBody }) => ({
  url: `/v1/auth/google_quick_login`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 谷歌一键注册
 */
export const authGoogleQuickRegister = createRequest<
  {
    requestBody?: { credential: string; invite_code?: string }
  },
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('authGoogleQuickRegister', ({ requestBody }) => ({
  url: `/v1/auth/google_quick_register`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 谷歌一键注册或登陆整合
 */
export const authGoogleQuickSignIn = createRequest<
  {
    requestBody?: { credential: string; invite_code?: string }
  },
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('authGoogleQuickSignIn', ({ requestBody }) => ({
  url: `/v1/auth/google_quick_sign_in`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 账号密码登录
 */
export const authLogin = createRequest<
  {
    requestBody?: { account: string; captcha: string; captcha_id: string; password: string; security_code?: string }
  },
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('authLogin', ({ requestBody }) => ({
  url: `/v1/auth/login`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 账号退出登录
 */
export const authLogout = createRequest<undefined, { code: number; msg: string; request_id: string }>(
  'authLogout',
  () => ({ url: `/v1/auth/logout`, method: 'GET', headers: { 'Service-Name': serviceName } }),
)

/**
 * 账号注册
 */
export const authRegister = createRequest<
  {
    requestBody?: {
      account: string
      captcha: string
      captcha_id: string
      invite_code?: string
      password: string
      password_confirm: string
    }
  },
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('authRegister', ({ requestBody }) => ({
  url: `/v1/auth/register`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 再次发送邮箱验证码
 */
export const authSendVerifyEmail = createRequest<
  {
    requestBody?: { captcha: string; captcha_id: string; email: string; type: number }
  },
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('authSendVerifyEmail', ({ requestBody }) => ({
  url: `/v1/auth/verify_email`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * Telegram一键登录
 */
export const authTelegramLogin = createRequest<
  {
    requestBody?: {
      auth_date: number
      first_name: string
      hash: string
      id: number
      invite_code?: string
      last_name: string
      photo_url: string
      username: string
    }
  },
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('authTelegramLogin', ({ requestBody }) => ({
  url: `/v1/auth/telegram_login`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * Tron一键登录-登录
 */
export const authTronLogin = createRequest<
  {
    requestBody?: { address: string; fingerprint?: string; invite_code?: string; signature: string }
  },
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('authTronLogin', ({ requestBody }) => ({
  url: `/v1/auth/tron_login`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * Tron一键登录-获取Nonce
 */
export const authTronNonce = createRequest<
  {
    requestBody?: { address: string; invite_code?: string }
  },
  { code: number; data: { nonce: string }; msg: string; request_id: string }
>('authTronNonce', ({ requestBody }) => ({
  url: `/v1/auth/tron_nonce`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 直接重置密码
 */
export const authUpdatePassword = createRequest<
  {
    requestBody?: { password: string; password_confirm: string; password_old: string }
  },
  IChengGong
>('authUpdatePassword', ({ requestBody }) => ({
  url: `/v1/auth/update_password`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 回调测试
 */
export const callbackOrderC2C = createRequest<undefined, {}>('callbackOrderC2C', () => ({
  url: `/callback/order_c2c`,
  method: 'POST',
  headers: { 'Service-Name': serviceName },
}))

/**
 * 获取验证码
 */
export const captchaGet = createRequest<
  {
    type?: string
  },
  { code: number; data: { data: string; id: string }; msg: string; request_id: string }
>('captchaGet', ({ type }) => ({
  url: `/v1/captcha/get`,
  method: 'GET',
  params: {
    type,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 提币详情
 */
export const cashDetail = createRequest<
  {
    order_no?: string
  },
  { code: number; data: null | ITiBiXiangQing; msg: string; pagination?: null; request_id: string }
>('cashDetail', ({ order_no }) => ({
  url: `/v1/cash/detail`,
  method: 'GET',
  params: {
    order_no,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 提币列表
 */
export const cashList = createRequest<
  {
    page?: number
    page_size?: number
  },
  { code: number; data: null | ITiBiXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>('cashList', ({ page, page_size }) => ({
  url: `/v1/cash/list`,
  method: 'GET',
  params: {
    page,
    page_size,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 提币准备
 */
export const cashReady = createRequest<
  {
    page?: number
    page_size?: number
    wallet_address?: string
  },
  {
    code: number
    data: {
      allow_cash: number
      cash_trx_min: number
      disable_msg?: string
      only_int: number
      tips: string
      trx_address: string
      trx_money: number
    }
    msg: string
    request_id: string
  }
>('cashReady', ({ page, page_size, wallet_address }) => ({
  url: `/v1/cash/ready`,
  method: 'GET',
  params: {
    page,
    page_size,
    wallet_address,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 提币确认请求
 */
export const cashSubmit = createRequest<
  {
    requestBody?: { amount: number; security_code?: string }
    uid?: number
  },
  { code: number; data: { amount: number; remark: string; trx_address: string }; msg: string; request_id: string }
>('cashSubmit', ({ uid, requestBody }) => ({
  url: `/v1/cash/submit`,
  method: 'POST',
  data: requestBody,
  params: {
    uid,
  },
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 检查下单可用能量
 */
export const checkEnergy = createRequest<
  {
    receive_address: string
    rent_duration?: number
  },
  { code: number; data: { usable_energy: number; usable_energy_3d: number }; msg: string; request_id: string }
>('checkEnergy', ({ receive_address, rent_duration }) => ({
  url: `/v1/order/check_energy`,
  method: 'GET',
  params: {
    receive_address,
    rent_duration,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 地址列表
 */
export const cronAddressList = createRequest<
  {
    page?: number
    page_size?: number
    wallet_address?: string
  },
  { code: number; data: null | IOrderCronDetail[]; msg: string; pagination?: IPagination; request_id: string }
>('cronAddressList', ({ page, page_size, wallet_address }) => ({
  url: `/v1/cron/address`,
  method: 'GET',
  params: {
    page,
    page_size,
    wallet_address,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 删除任务地址
 */
export const cronDeleteAddress = createRequest<
  {
    requestBody?: { resource_type: number; wallet_address: string }
    uid?: number
  },
  { code: number; msg: string; request_id: string }
>('cronDeleteAddress', ({ uid, requestBody }) => ({
  url: `/v1/cron/delete_address`,
  method: 'POST',
  data: requestBody,
  params: {
    uid,
  },
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 即用任务地址
 */
export const cronDisable = createRequest<
  {
    requestBody?: { resource_type: number; wallet_address: string }
    uid?: number
  },
  { code: number; data?: null | IOrderCronDetail; msg: string; request_id: string }
>('cronDisable', ({ uid, requestBody }) => ({
  url: `/v1/cron/disable`,
  method: 'POST',
  data: requestBody,
  params: {
    uid,
  },
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 启用任务地址
 */
export const cronEnable = createRequest<
  {
    requestBody?: { resource_type: number; wallet_address: string }
    uid?: number
  },
  { code: number; data?: null | IOrderCronDetail; msg: string; request_id: string }
>('cronEnable', ({ uid, requestBody }) => ({
  url: `/v1/cron/enable`,
  method: 'POST',
  data: requestBody,
  params: {
    uid,
  },
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 添加任务地址
 */
export const cronInsertAddress = createRequest<
  {
    requestBody?: {
      amount_limit?: number
      count_limit?: number
      end_time?: number
      limit_type: number
      order_resource_value: number
      order_type: number
      price_in_sun?: number
      remark?: string
      rent_time_second: number
      resource_threshold: number
      resource_type: number
      start_time?: number
      status: number
      wallet_address: string
    }
    uid?: number
  },
  { code: number; data?: null | IOrderCronDetail; msg: string; request_id: string }
>('cronInsertAddress', ({ uid, requestBody }) => ({
  url: `/v1/cron/insert_address`,
  method: 'POST',
  data: requestBody,
  params: {
    uid,
  },
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 计划任务订单列表
 */
export const cronOrderList = createRequest<
  {
    end_time?: string
    filter?: number
    order_no?: string
    page?: number
    page_size?: number
    resource_type: number
    start_time?: string
    wallet_address: string
  },
  { code: number; data: null | IZuYongDingDanXiangQing[]; msg: string; pagination?: IPagination; request_id: string }
>('cronOrderList', ({ page, page_size, order_no, start_time, end_time, wallet_address, filter, resource_type }) => ({
  url: `/v1/cron/order_list`,
  method: 'GET',
  params: {
    page,
    page_size,
    order_no,
    start_time,
    end_time,
    wallet_address,
    filter,
    resource_type,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 重置使用量
 */
export const cronResetUsage = createRequest<
  {
    requestBody?: { resource_type: number; wallet_address: string }
    uid?: number
  },
  { code: number; data?: null | IOrderCronDetail; msg: string; request_id: string }
>('cronResetUsage', ({ uid, requestBody }) => ({
  url: `/v1/cron/reset_usage`,
  method: 'POST',
  data: requestBody,
  params: {
    uid,
  },
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 编辑任务地址
 */
export const cronUpdateAddress = createRequest<
  {
    requestBody?: {
      amount_limit?: number
      count_limit?: number
      end_time?: number
      limit_type: number
      order_resource_value: number
      order_type: number
      price_in_sun?: number
      remark?: string
      rent_time_second: number
      resource_threshold: number
      resource_type: number
      start_time?: number
      wallet_address: string
    }
    uid?: number
  },
  { code: number; data?: null | IOrderCronDetail; msg: string; request_id: string }
>('cronUpdateAddress', ({ uid, requestBody }) => ({
  url: `/v1/cron/update_address`,
  method: 'POST',
  data: requestBody,
  params: {
    uid,
  },
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 兑换下单后端广播
 */
export const exchangeCreateOrder = createRequest<
  {
    requestBody?: {
      exchange_symbol: string
      pay_symbol?: string
      receive_address: string
      signed_txn?: ISignedTransaction
    }
  },
  { code: number; data: IDuiHuanDingDanXiangQing; msg: string; request_id: string }
>('exchangeCreateOrder', ({ requestBody }) => ({
  url: `/v1/exchange/create_order`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 兑换记录
 */
export const exchangeList = createRequest<
  {
    order_no?: string
    page?: number
    page_size?: number
  },
  { code: number; data: null | IDuiHuanDingDanXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>('exchangeList', ({ order_no, page, page_size }) => ({
  url: `/v1/exchange/list`,
  method: 'GET',
  params: {
    order_no,
    page,
    page_size,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 兑换下单预请求
 */
export const exchangePreSubmit = createRequest<
  {
    requestBody?: { exchange_symbol: string; pay_address?: string; pay_amount: number; pay_symbol: string }
  },
  { code: number; data: IEstimateTransactionDetail; msg: string; request_id: string }
>('exchangePreSubmit', ({ requestBody }) => ({
  url: `/v1/exchange/pre_submit`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 支付后的检查
 */
export const exchangeQuery = createRequest<
  {
    order_no: string
    trx_address: string
    tx_id: string
  },
  { code: number; data?: null | IDuiHuanDingDanXiangQing; msg: string; request_id: string }
>('exchangeQuery', ({ tx_id, order_no, trx_address }) => ({
  url: `/v1/exchange/query`,
  method: 'GET',
  params: {
    tx_id,
    order_no,
    trx_address,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 兑换下单请求
 */
export const exchangeSubmit = createRequest<
  {
    requestBody?: {
      exchange_symbol: string
      order_no: string
      pay_address: string
      pay_amount: number
      pay_symbol: string
      pay_tx_id: string
      receive_address: string
    }
  },
  { code: number; data: IDuiHuanDingDanXiangQing; msg: string; request_id: string }
>('exchangeSubmit', ({ requestBody }) => ({
  url: `/v1/exchange/submit`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 兑换统计
 */
export const exchangeTotalStatistics = createRequest<
  undefined,
  { code: number; data: { exchange_amount_trx: number; exchange_amount_usdt: number }; msg: string; request_id: string }
>('exchangeTotalStatistics', () => ({
  url: `/v1/exchange/total_statistics`,
  method: 'GET',
  headers: { 'Service-Name': serviceName },
}))

/**
 * 忘记密码验证
 */
export const forgetPasswordVerifyApi = createRequest<
  {
    requestBody?: { email: string; verify_code: string }
  },
  IChengGong
>('forgetPasswordVerifyApi', ({ requestBody }) => ({
  url: `/v1/auth/forget_password_verify`,
  method: 'GET',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 检查程序版本
 */
export const initCheckVersion = createRequest<
  {
    p?: string
  },
  {}
>('initCheckVersion', ({ p }) => ({
  url: `/v1/init/check_version`,
  method: 'HEAD',
  params: {
    p,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 握手协议(新)
 */
export const initConfig = createRequest<
  {
    trx_address?: string
  },
  {
    code: number
    data: {
      activity: { free_order: number }
      agent: {
        agent_id: string
        allow_customize_price: number
        announcement_cn: string
        announcement_en: string
        increase_sun: number
        show_last_trades: number
        theme: string
      }
      base: {
        cdn_domain: string
        enable_ttpay: number
        seller_entry_threshold: number
        site_name: string
        telegram_bot_name: string
        telegram_bot_username: string
        telegram_business_customer_id: string
        telegram_channel_id: string
        telegram_customer_id: string
        usdt_price_in_trx: number
        web_domain: string
      }
      estimate: {
        energy_price: number
        have_token_energy_fee: number
        have_token_energy_used: number
        not_have_token_energy_fee: number
        not_have_token_energy_used: number
      }
      exchange: {
        buy_trx_min_amount: number
        enable_buy_trx: number
        enable_exchange: number
        exchange_profit_ratio: number
        trx_min_exchange_amount: number
        usdt_min_exchange_amount: number
      }
      order: {
        allow_order_auto_cancel: number
        bandwidth_c2c_max_value: number
        bandwidth_c2c_min_value: number
        bandwidth_quick_options?: number[]
        energy_b2c_quick_options?: number[]
        energy_c2c_max_value: number
        energy_c2c_min_value: number
        energy_c2c_quick_options?: number[]
        energy_max_value: number
        energy_min_value: number
        time_range_b2c: number[]
        time_range_c2c: number[]
      }
      price_b2c: {
        energy: {
          service_amount: number
          service_amount_limit: number
          sun: number
          sun_1d: number
          sun_1h: number
          sun_2d: number
          sun_3h: number
          sun_m: number
        }
      }
      price_c2c: {
        bandwidth: {
          default_sun: number
          default_sun_1d: number
          default_sun_1h: number
          default_sun_3h: number
          default_sun_m: number
          max_sun: number
          sun: number
          sun_1d: number
          sun_1h: number
          sun_3h: number
          sun_m: number
        }
        energy: {
          default_sun: number
          default_sun_1d: number
          default_sun_1h: number
          default_sun_3h: number
          default_sun_m: number
          max_sun: number
          sun: number
          sun_1d: number
          sun_1h: number
          sun_3h: number
          sun_m: number
        }
      }
      promote: { exchange_commission_ratio: number; order_commission_ratio: number }
      session_id: string
      version: { agent_version: string; console_version: string; web_version: string; www_version: string }
      vote: {
        vote_extra_rewards_rate: number
        vote_platform_extra_rewards_rate: number
        vote_target_address: string
        vote_tron_node_annualized_rate: number
      }
      wallet: {
        payment_b2c_address: string
        payment_c2c_address: string
        payment_exchange_address: string
        recharge_address: string
        transfer_b2c_address: string
        transfer_exchange_address: string
      }
    }
    msg: string
    request_id: string
  }
>('initConfig', ({ trx_address }) => ({
  url: `/v1/init/config`,
  method: 'GET',
  params: {
    trx_address,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 握手协议
 */
export const initHandshake = createRequest<
  {
    trx_address?: string
  },
  {
    code: number
    data: {
      allow_customize_price: number
      allow_order_auto_cancel: number
      announcement_cn: string
      announcement_en: string
      bandwidth_c2c_max_value: number
      bandwidth_c2c_min_value: number
      bandwidth_quick_options?: number[]
      buy_trx_min_amount: number
      c2c_bandwidth_default_sun: number
      c2c_bandwidth_max_sun: number
      c2c_bandwidth_min_sun: number
      c2c_energy_default_sun: number
      c2c_energy_max_sun: number
      c2c_energy_min_sun: number
      cdn_domain: string
      console_version: string
      enable_buy_trx: number
      enable_exchange: number
      enable_ttpay: number
      energy_b2c_quick_options?: number[]
      energy_c2c_max_value: number
      energy_c2c_min_value: number
      energy_c2c_quick_options?: number[]
      energy_max_value: number
      energy_min_value: number
      energy_price: number
      estimate: {
        have_token_energy_fee: number
        have_token_energy_used: number
        not_have_token_energy_fee: number
        not_have_token_energy_used: number
      }
      exchange_commission_ratio: number
      exchange_profit_ratio: number
      increase_sun: number
      ladder: {
        extra_add_day: number
        max: number
        min: number
        sun: number
        sun_1d: number
        sun_1h: number
        sun_2d: number
        sun_3h: number
      }[]
      order_commission_ratio: number
      payment_b2c_address: string
      payment_c2c_address: string
      payment_exchange_address: string
      price_in_sun: number
      recharge_address: string
      seller_entry_threshold: number
      session_id: string
      show_last_trades: number
      site_name: string
      sun: {
        service_amount: number
        service_amount_limit: number
        sun: number
        sun_1d: number
        sun_1h: number
        sun_2d: number
        sun_3h: number
        sun_m: number
      }
      sun_c2c: {
        default_sun: number
        default_sun_1d: number
        default_sun_1h: number
        default_sun_3h: number
        default_sun_m: number
        sun: number
        sun_1d: number
        sun_1h: number
        sun_3h: number
        sun_m: number
      }
      telegram_bot_name: string
      telegram_bot_username: string
      telegram_business_customer_id: string
      telegram_channel_id: string
      telegram_customer_id: string
      time_range_b2c: number[]
      time_range_c2c: number[]
      transfer_b2c_address: string
      transfer_exchange_address: string
      trx_min_exchange_amount: number
      trx_min_transfer_amount: number
      usdt_min_exchange_amount: number
      usdt_min_transfer_amount: number
      usdt_price_in_trx: number
      vote: {
        vote_extra_rewards_rate: number
        vote_platform_extra_rewards_rate: number
        vote_target_address: string
        vote_tron_node_annualized_rate: number
      }
      web_domain: string
      www_version: string
    }
    msg: string
    request_id: string
  }
>('initHandshake', ({ trx_address }) => ({
  url: `/v1/init/handshake`,
  method: 'GET',
  params: {
    trx_address,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 绑定钱包
 */
export const linkWalletApi = createRequest<
  {
    requestBody?: { account: string; address: string }
  },
  {}
>('linkWalletApi', ({ requestBody }) => ({
  url: `/v1/auth/link_wallet`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 地址列表
 */
export const monitorAddressList = createRequest<
  {
    page?: number
    page_size?: number
    wallet_address?: string
  },
  { code: number; data: null | IJianKongDiZhiXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>('monitorAddressList', ({ page, page_size, wallet_address }) => ({
  url: `/v1/monitor/address`,
  method: 'GET',
  params: {
    page,
    page_size,
    wallet_address,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 删除监控地址
 */
export const monitorDeleteAddress = createRequest<
  {
    requestBody?: { wallet_address?: string }
    uid?: number
  },
  { code: number; msg: string; request_id: string }
>('monitorDeleteAddress', ({ uid, requestBody }) => ({
  url: `/v1/monitor/delete_address`,
  method: 'POST',
  data: requestBody,
  params: {
    uid,
  },
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 添加监控地址
 */
export const monitorInsertAddress = createRequest<
  {
    requestBody?: {
      chain?: string
      notify_url?: string
      receive_amount_limit?: number
      receive_in?: number
      receive_notify_url?: number
      receive_out?: number
      receive_telegram?: number
      receive_token?: number
      receive_usdt?: number
      remark?: string
      wallet_address?: string
    }
    uid?: number
  },
  { code: number; data?: null | IJianKongDiZhiXiangQing; msg: string; request_id: string }
>('monitorInsertAddress', ({ uid, requestBody }) => ({
  url: `/v1/monitor/insert_address`,
  method: 'POST',
  data: requestBody,
  params: {
    uid,
  },
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 交易明细
 */
export const monitorTransactionList = createRequest<
  {
    act?: string
    chain?: string
    end_time?: string
    page?: number
    page_size?: number
    start_time?: string
    symbol?: string
    wallet_address?: string
  },
  { code: number; data: null | IJianKongJiaoYiMingXi[]; msg: string; pagination: IPagination; request_id: string }
>('monitorTransactionList', ({ page, page_size, chain, symbol, act, wallet_address, start_time, end_time }) => ({
  url: `/v1/monitor/transaction`,
  method: 'GET',
  params: {
    page,
    page_size,
    chain,
    symbol,
    act,
    wallet_address,
    start_time,
    end_time,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 修改监控地址
 */
export const monitorUpdateAddress = createRequest<
  {
    requestBody?: {
      notify_url?: string
      receive_amount_limit?: number
      receive_in?: number
      receive_notify_url?: number
      receive_out?: number
      receive_telegram?: number
      receive_token?: number
      receive_usdt?: number
      remark?: string
      status?: number
      wallet_address?: string
    }
    uid?: number
  },
  { code: number; data?: null | IJianKongDiZhiXiangQing; msg: string; request_id: string }
>('monitorUpdateAddress', ({ uid, requestBody }) => ({
  url: `/v1/monitor/update_address`,
  method: 'POST',
  data: requestBody,
  params: {
    uid,
  },
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 我的申请
 */
export const myAgentApply = createRequest<
  undefined,
  { code: number; data: IDaiLiShenQingXiangQing; msg: string; request_id: string }
>('myAgentApply', () => ({ url: `/v1/agent/my_apply`, method: 'GET', headers: { 'Service-Name': serviceName } }))

/**
 * 我委托的订单V2
 */
export const myDelegate = createRequest<
  {
    trx_address: string
  },
  { code: number; data: null | IWeiTuoDingDanV2[]; msg: string; request_id: string }
>('myDelegate', ({ trx_address }) => ({
  url: `/v1/order/mydelegate`,
  method: 'GET',
  params: {
    trx_address,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 我的买单
 */
export const myPayOrder = createRequest<
  {
    page?: number
    page_size?: number
    range?: number
    trx_address: string
  },
  { code: number; data: null | IZuYongDingDanXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>('myPayOrder', ({ page, page_size, trx_address, range }) => ({
  url: `/v1/order/mypayorder`,
  method: 'GET',
  params: {
    page,
    page_size,
    trx_address,
    range,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * C2C取消请求
 */
export const orderCancelC2C = createRequest<
  {
    requestBody?: { order_no: string; signed_txn?: ISignedTransaction }
  },
  { code: number; data: IZuYongDingDanXiangQing; msg: string; request_id: string }
>('orderCancelC2C', ({ requestBody }) => ({
  url: `/v1/order/cancel_c2c`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 取消订单后的轮询
 */
export const orderCancelQuery = createRequest<
  {
    order_no: string
    trx_address?: string
    tx_id?: string
  },
  { code: number; data: null | IZuYongDingDanXiangQing; msg: string; request_id: string }
>('orderCancelQuery', ({ tx_id, order_no, trx_address }) => ({
  url: `/v1/order/cancel_query`,
  method: 'GET',
  params: {
    tx_id,
    order_no,
    trx_address,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * C2C下单检测资源
 */
export const orderCheckResourceC2C = createRequest<
  {
    requestBody?: {
      price_in_sun: number
      receive_address: string
      rent_duration?: number
      rent_time_unit?: string
      resource_type?: number
      resource_value: number
    }
  },
  { code: number; data: { fill: boolean; fill_msg: string }; msg: string; request_id: string }
>('orderCheckResourceC2C', ({ requestBody }) => ({
  url: `/v1/order/check_resource`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * B2C下单请求
 */
export const orderCreateB2C = createRequest<
  {
    requestBody?: {
      auto_to_market: number
      pay_symbol?: string
      receive_address: string
      rent_duration?: number
      rent_time_unit: string
      resource_type: number
      resource_value: number
      signed_txn?: ISignedTransaction
      wait_second: number
    }
  },
  { code: number; data: IZuYongDingDanXiangQing; msg: string; request_id: string }
>('orderCreateB2C', ({ requestBody }) => ({
  url: `/v1/order/create_b2c`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * C2C下单请求
 */
export const orderCreateC2C = createRequest<
  {
    requestBody?: {
      price_in_sun: number
      receive_address: string
      rent_duration?: number
      rent_time_second?: number
      rent_time_unit?: string
      resource_type: number
      resource_value: number
      signed_txn?: ISignedTransaction
      wait_second: number
    }
  },
  { code: number; data: IZuYongDingDanXiangQing; msg: string; request_id: string }
>('orderCreateC2C', ({ requestBody }) => ({
  url: `/v1/order/create_c2c`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * C2C出售委托请求
 */
export const orderDelegateC2C = createRequest<
  {
    requestBody?: {
      order_no: string
      resource_type?: number
      resource_value: number
      settle_address: string
      signed_txn?: ISignedTransaction
    }
  },
  { code: number; data: IZuYongDingDanXiangQing; msg: string; request_id: string }
>('orderDelegateC2C', ({ requestBody }) => ({
  url: `/v1/order/delegate_c2c`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 委托后的轮询
 */
export const orderDelegateQuery = createRequest<
  {
    order_no: string
    trx_address?: string
    tx_id?: string
  },
  { code: number; data?: null | IZuYongDingDanXiangQing; msg: string; request_id: string }
>('orderDelegateQuery', ({ tx_id, order_no, trx_address }) => ({
  url: `/v1/order/delegate_query`,
  method: 'GET',
  params: {
    tx_id,
    order_no,
    trx_address,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 订单详情
 */
export const orderDetail = createRequest<
  {
    order_no: string
  },
  { code: number; data: null | IZuYongDingDanXiangQing; msg: string; pagination?: null; request_id: string }
>('orderDetail', ({ order_no }) => ({
  url: `/v1/order/detail`,
  method: 'GET',
  params: {
    order_no,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 用户后台订单导出Excel
 */
export const orderExportExcel = createRequest<
  {
    amount_max?: number
    amount_min?: number
    api_key?: string
    end_time?: string
    filter?: number
    order_no?: string
    order_parent_no?: string
    page?: number
    page_size?: number
    receive_address?: string
    start_time?: string
    status?: number
  },
  { code: number; data: null | IZuYongDingDanXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>(
  'orderExportExcel',
  ({
    page,
    page_size,
    api_key,
    order_no,
    start_time,
    end_time,
    amount_min,
    amount_max,
    status,
    receive_address,
    filter,
    order_parent_no,
  }) => ({
    url: `/v1/order/buy_order_export`,
    method: 'GET',
    params: {
      page,
      page_size,
      api_key,
      order_no,
      start_time,
      end_time,
      amount_min,
      amount_max,
      status,
      receive_address,
      filter,
      order_parent_no,
    },
    headers: { 'Service-Name': serviceName },
  }),
)

/**
 * 最近完成订单
 */
export const orderLastTrades = createRequest<
  {
    page?: number
    page_size?: number
    sort?: number
  },
  { code: number; data: null | ILastTradeDetail[]; msg: string; pagination: IPagination; request_id: string }
>('orderLastTrades', ({ page, page_size, sort }) => ({
  url: `/v1/order/last_trades`,
  method: 'GET',
  params: {
    page,
    page_size,
    sort,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 租用订单列表
 */
export const orderList = createRequest<
  {
    page?: number
    page_size?: number
    trx_address: string
  },
  { code: number; data: null | IZuYongDingDanXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>('orderList', ({ page, page_size, trx_address }) => ({
  url: `/v1/order/list`,
  method: 'GET',
  params: {
    page,
    page_size,
    trx_address,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 用户后台订单列表
 */
export const orderListNew = createRequest<
  {
    amount_max?: number
    amount_min?: number
    api_key?: string
    end_time?: string
    filter?: number
    order_no?: string
    order_parent_no?: string
    page?: number
    page_size?: number
    receive_address?: string
    start_time?: string
    status?: number
  },
  { code: number; data: null | IZuYongDingDanXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>(
  'orderListNew',
  ({
    page,
    page_size,
    api_key,
    order_no,
    start_time,
    end_time,
    amount_min,
    amount_max,
    status,
    receive_address,
    filter,
    order_parent_no,
  }) => ({
    url: `/v1/order/list_new`,
    method: 'GET',
    params: {
      page,
      page_size,
      api_key,
      order_no,
      start_time,
      end_time,
      amount_min,
      amount_max,
      status,
      receive_address,
      filter,
      order_parent_no,
    },
    headers: { 'Service-Name': serviceName },
  }),
)

/**
 * 大家在租
 */
export const orderNewest = createRequest<
  {
    page?: number
    page_size?: number
  },
  { code: number; data: null | IDaJiaZaiZuDingDanXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>('orderNewest', ({ page, page_size }) => ({
  url: `/v1/order/newest`,
  method: 'GET',
  params: {
    page,
    page_size,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 发起取消预请求
 */
export const orderPreCancel = createRequest<
  {
    requestBody?: { order_no: string; pay_address: string; pay_tx_id?: string; sign_step?: number }
  },
  { code: number; data?: IZuYongDingDanXiangQing; msg: string; request_id: string }
>('orderPreCancel', ({ requestBody }) => ({
  url: `/v1/order/pre_cancel`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * B2C下单预请求
 */
export const orderPreCreateB2C = createRequest<
  {
    requestBody?: {
      auto_to_market?: number
      pay_symbol: string
      receive_address: string
      rent_duration?: number
      rent_time_unit: string
      resource_type: number
      resource_value: number
    }
  },
  { code: number; data: IZuYongDingDanXiangQing; msg: string; request_id: string }
>('orderPreCreateB2C', ({ requestBody }) => ({
  url: `/v1/order/pre_create_b2c`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * C2C发起出售委托预请求
 */
export const orderPreDelegateC2C = createRequest<
  {
    requestBody?: {
      frozen_balance: number
      order_no: string
      owner_address: string
      permission_id?: number
      resource_type: number
      resource_value: number
      skip_duplicate_check?: number
    }
  },
  { code: number; data?: IZuYongDingDanXiangQing; msg: string; request_id: string }
>('orderPreDelegateC2C', ({ requestBody }) => ({
  url: `/v1/order/pre_delegate_c2c`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 支付后的检查
 */
export const orderQuery = createRequest<
  {
    order_no: string
    trx_address?: string
    tx_id?: string
  },
  { code: number; data: null | IZuYongDingDanXiangQing; msg: string; pagination: IPagination; request_id: string }
>('orderQuery', ({ tx_id, order_no, trx_address }) => ({
  url: `/v1/order/query`,
  method: 'GET',
  params: {
    tx_id,
    order_no,
    trx_address,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 进行中订单列表
 */
export const orderTrades = createRequest<
  {
    page?: number
    page_size?: number
    resource_type?: number
    sort?: number
  },
  { code: number; data: null | ITradeDetail[]; msg: string; pagination: IPagination; request_id: string }
>('orderTrades', ({ page, page_size, sort, resource_type }) => ({
  url: `/v1/order/trades`,
  method: 'GET',
  params: {
    page,
    page_size,
    sort,
    resource_type,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 进行中订单详情
 */
export const orderTradesDetail = createRequest<
  {
    order_no?: string
  },
  { code: number; data: null | ITradeDetail; msg: string; request_id: string }
>('orderTradesDetail', ({ order_no }) => ({
  url: `/v1/order/trades_detail`,
  method: 'GET',
  params: {
    order_no,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 绑定关系
 */
export const promoteBind = createRequest<
  {
    requestBody?: { address?: string; invite_code?: string }
    uid?: number
  },
  { code: number; data?: string; msg: string; request_id: string }
>('promoteBind', ({ uid, requestBody }) => ({
  url: `/v1/promote/bind`,
  method: 'POST',
  data: requestBody,
  params: {
    uid,
  },
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 推广佣金明细
 */
export const promoteFundDetails = createRequest<
  {
    from_address?: string
    from_user_id?: number
    page?: number
    page_size?: number
  },
  { code: number; data: null | IYongHuZhangBianXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>('promoteFundDetails', ({ page, page_size, from_address, from_user_id }) => ({
  url: `/v1/promote/funddetails`,
  method: 'GET',
  params: {
    page,
    page_size,
    from_address,
    from_user_id,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 推广数据
 */
export const promoteInfo = createRequest<
  undefined,
  {
    code: number
    data: {
      invite_url: string
      promote_address_count: number
      promote_total_amount: number
      promote_user_count: number
    }
    msg: string
    request_id: string
  }
>('promoteInfo', () => ({ url: `/v1/promote/info`, method: 'GET', headers: { 'Service-Name': serviceName } }))

/**
 * 推广钱包绑定明细
 */
export const promoteList = createRequest<
  {
    address?: string
    end_time?: string
    page?: number
    page_size?: number
    start_time?: string
  },
  { code: number; data: null | ITuiGuangYongHuMingXi[]; msg: string; pagination: IPagination; request_id: string }
>('promoteList', ({ page, page_size, address, start_time, end_time }) => ({
  url: `/v1/promote/list`,
  method: 'GET',
  params: {
    page,
    page_size,
    address,
    start_time,
    end_time,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 导出推荐的订单列表（已完成）
 */
export const promoteOrderExport = createRequest<
  {
    end_time?: number
    start_time?: number
  },
  { code: number; data: null | IZuYongDingDanXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>('promoteOrderExport', ({ start_time, end_time }) => ({
  url: `/v1/promote/order_export`,
  method: 'GET',
  params: {
    start_time,
    end_time,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 推荐的订单列表
 */
export const promoteOrderList = createRequest<
  {
    end_time?: number
    filter?: number
    order_no?: string
    page?: number
    page_size?: number
    start_time?: number
  },
  { code: number; data: null | IZuYongDingDanXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>('promoteOrderList', ({ order_no, start_time, end_time, page, page_size, filter }) => ({
  url: `/v1/promote/order_list`,
  method: 'GET',
  params: {
    order_no,
    start_time,
    end_time,
    page,
    page_size,
    filter,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 推广用户注册明细
 */
export const promoteUsers = createRequest<
  {
    address?: string
    end_time?: string
    page?: number
    page_size?: number
    start_time?: string
    telegram_username?: string
  },
  { code: number; data: null | {}[]; msg: string; pagination: IPagination; request_id: string }
>('promoteUsers', ({ page, page_size, address, telegram_username, start_time, end_time }) => ({
  url: `/v1/promote/users`,
  method: 'GET',
  params: {
    page,
    page_size,
    address,
    telegram_username,
    start_time,
    end_time,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 注册验证
 */
export const registerVerifyApi = createRequest<
  {
    email?: string
    verify_code?: string
  },
  IChengGong
>('registerVerifyApi', ({ email, verify_code }) => ({
  url: `/v1/auth/register_verify`,
  method: 'GET',
  params: {
    email,
    verify_code,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 重设密码
 */
export const resetPassword = createRequest<
  {
    requestBody?: { email: string; password: string; password_confirm: string; verify_code: string }
  },
  IChengGong
>('resetPassword', ({ requestBody }) => ({
  url: `/v1/auth/reset_password`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 添加授权钱包
 */
export const sellerAuthorize = createRequest<
  {
    requestBody?: { take_order: number; wallet_address: string }
  },
  { code: number; data: IMaiJiaXiangQing; msg: string; request_id: string }
>('sellerAuthorize', ({ requestBody }) => ({
  url: `/v1/seller/authorize`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 修改授权钱包
 */
export const sellerAuthorizeChange = createRequest<
  {
    requestBody?: { take_order: number; wallet_address: string }
  },
  { code: number; data: IMaiJiaXiangQing; msg: string; request_id: string }
>('sellerAuthorizeChange', ({ requestBody }) => ({
  url: `/v1/seller/authorize_change`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 卖家设置
 */
export const sellerEdit = createRequest<
  {
    requestBody?: {
      auto_freeze_v2: number
      auto_vote: number
      auto_withdraw_reward: number
      freezev2_keep_amount: number
      seller_id?: number
      settle_expected_ratio: number
      take_order?: number
      take_order_bandwidth_min_sun: number
      take_order_energy_min_sun: number
      take_order_funds: number
      take_order_resource: number
      take_order_time_type: number
      take_order_time_type_bandwidth: number
      withdraw_keep_amount: number
      withdraw_type: number
    }
    uid?: number
  },
  { code: number; data: IMaiJiaXiangQing; msg: string; request_id: string }
>('sellerEdit', ({ uid, requestBody }) => ({
  url: `/v1/seller/edit`,
  method: 'POST',
  data: requestBody,
  params: {
    uid,
  },
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 授权操作明细（交易明细）
 */
export const sellerFunding = createRequest<
  {
    end_time?: string
    page?: number
    page_size?: number
    start_time?: string
    txid?: string
    type?: string
  },
  {
    code: number
    data: null | IShouQuanCaoZuoMingXiJiaoYiMingXi[]
    msg: string
    pagination: IPagination
    request_id: string
  }
>('sellerFunding', ({ page, page_size, txid, type, start_time, end_time }) => ({
  url: `/v1/seller/funding`,
  method: 'GET',
  params: {
    page,
    page_size,
    txid,
    type,
    start_time,
    end_time,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 获取授权配置
 */
export const sellerGetAuthorizeConfig = createRequest<
  undefined,
  { code: number; data: { name: string; wallet: string }; msg: string; request_id: string }
>('sellerGetAuthorizeConfig', () => ({
  url: `/v1/seller/get_authorize_config`,
  method: 'GET',
  headers: { 'Service-Name': serviceName },
}))

/**
 * 我的卖家
 */
export const sellerList = createRequest<
  {
    page?: number
    page_size?: number
  },
  { code: number; data: null | IMaiJiaXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>('sellerList', ({ page, page_size }) => ({
  url: `/v1/seller/list`,
  method: 'GET',
  params: {
    page,
    page_size,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 卖家的出售订单列表导出Excel
 */
export const sellerOrderExport = createRequest<
  {
    end_time?: string
    start_time?: string
  },
  { code: number; data: null | {}[]; msg: string; pagination: IPagination; request_id: string }
>('sellerOrderExport', ({ start_time, end_time }) => ({
  url: `/v1/seller/order_export`,
  method: 'GET',
  params: {
    start_time,
    end_time,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 卖家的出售订单列表
 */
export const sellerOrderList = createRequest<
  {
    end_time?: string
    filter?: string
    one_page?: number
    order_no?: string
    page?: number
    page_size?: number
    receive_address?: string
    resource_type?: string
    start_time?: string
  },
  { code: number; data: null | {}[]; msg: string; pagination: IPagination; request_id: string }
>(
  'sellerOrderList',
  ({ page, page_size, start_time, end_time, order_no, receive_address, resource_type, filter, one_page }) => ({
    url: `/v1/seller/order`,
    method: 'GET',
    params: {
      page,
      page_size,
      start_time,
      end_time,
      order_no,
      receive_address,
      resource_type,
      filter,
      one_page,
    },
    headers: { 'Service-Name': serviceName },
  }),
)

/**
 * 月报表汇总
 */
export const sellerOrderStatement = createRequest<
  {
    page?: number
    page_size?: number
  },
  {
    code: number
    data: null | IMaiJiaDingDanYueBaoBiaoXiangQing[]
    msg: string
    pagination: { has_more: boolean; page: number; page_size: number; total: number }
    request_id: string
  }
>('sellerOrderStatement', ({ page, page_size }) => ({
  url: `/v1/seller/statement`,
  method: 'GET',
  params: {
    page,
    page_size,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 卖家的统计概览
 */
export const sellerStatistics = createRequest<
  undefined,
  { code: number; data: IMaiJiaGaiLanTongJi; msg: string; request_id: string }
>('sellerStatistics', () => ({
  url: `/v1/seller/statistics`,
  method: 'GET',
  headers: { 'Service-Name': serviceName },
}))

/**
 * 用户后台子订单列表
 */
export const subOrderList = createRequest<
  {
    order_parent_no: string
    page?: number
    page_size?: number
  },
  { code: number; data: null | IZuYongDingDanXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>('subOrderList', ({ page, page_size, order_parent_no }) => ({
  url: `/v1/order/sublist`,
  method: 'GET',
  params: {
    page,
    page_size,
    order_parent_no,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 官网&用户中心子订单列表
 */
export const subOrderListByAddress = createRequest<
  {
    order_parent_no: string
    page?: number
    page_size?: number
    trx_address?: string
  },
  { code: number; data: null | IZuYongDingDanXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>('subOrderListByAddress', ({ page, page_size, order_parent_no, trx_address }) => ({
  url: `/v1/order/suborder`,
  method: 'GET',
  params: {
    page,
    page_size,
    order_parent_no,
    trx_address,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * TTPAY下单B2C
 */
export const ttpayOrderB2C = createRequest<
  {
    requestBody?: {
      auto_to_market: number
      pay_amount: number
      receive_address: string
      rent_duration: number
      rent_time_unit: string
      resource_value: number
    }
  },
  { code: number; data: ITtPayOrderResp | null; msg: string; request_id: string }
>('ttpayOrderB2C', ({ requestBody }) => ({
  url: `/v1/ttpay/order_b2c`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * TTPAY下单C2C
 */
export const ttpayOrderC2C = createRequest<
  {
    requestBody?: {
      pay_amount: number
      price_in_sun: number
      receive_address: string
      rent_duration?: number
      rent_time_second?: number
      rent_time_unit?: string
      resource_type: number
      resource_value: number
      return_url?: string
    }
  },
  { code: number; data?: ITtPayOrderResp; msg: string; request_id: string }
>('ttpayOrderC2C', ({ requestBody }) => ({
  url: `/v1/ttpay/order_c2c`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * TTPAY充值
 */
export const ttpayRecharge = createRequest<
  {
    requestBody?: { amount: number }
  },
  { code: number; data?: ITtPayOrderResp; msg: string; request_id: string }
>('ttpayRecharge', ({ requestBody }) => ({
  url: `/v1/ttpay/recharge`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 获取可用资源
 */
export const usableEnergy = createRequest<
  undefined,
  {
    code: number
    data: {
      energy_available: { '1d': number; '1h': number; '2d': number; '3d': number; '3h': number }
      energy_freed: {
        '12h': number
        '15m': number
        '1h': number
        '24h': number
        '2h': number
        '30m': number
        '6h': number
      }
      energy_value: number
      freed_balance?: number
      freed_band_width?: number
      freed_energy: number
      have_token_energy_fee: number
      have_token_energy_used: number
      not_have_token_energy_fee: number
      not_have_token_energy_used: number
      order_max_energy: number
      price_in_sun: number
      save_trx: number
      total_energy: number
      usable_amount: number
      usable_energy: number
    }
    msg: string
    request_id: string
  }
>('usableEnergy', () => ({ url: `/v1/order/usable_energy`, method: 'GET', headers: { 'Service-Name': serviceName } }))

/**
 * Telegram绑定
 */
export const userBindTelegram = createRequest<
  {
    requestBody?: {
      auth_date: number
      first_name: string
      hash: string
      id: number
      last_name: string
      photo_url: string
      username: string
    }
  },
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('userBindTelegram', ({ requestBody }) => ({
  url: `/v1/user/bindtelegram`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * Tron钱包绑定-Nonce
 */
export const userBindTronNonce = createRequest<
  {
    requestBody?: { address: string }
  },
  { code: number; data: { nonce: string }; msg: string; request_id: string }
>('userBindTronNonce', ({ requestBody }) => ({
  url: `/v1/user/bindtronnonce`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * Tron钱包绑定-绑定
 */
export const userBindTronWallet = createRequest<
  {
    requestBody?: { address: string; fingerprint?: string; signature: string }
  },
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('userBindTronWallet', ({ requestBody }) => ({
  url: `/v1/user/bindtronwallet`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 用户充值请求
 */
export const userDoRecharge = createRequest<
  {
    requestBody?: { signed_txn?: ISignedTransaction; user_id: number }
  },
  { code: number; data: IChongZhiXiangQing; msg: string; request_id: string }
>('userDoRecharge', ({ requestBody }) => ({
  url: `/v1/user/recharge`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 我的账变
 */
export const userFundDetails = createRequest<
  {
    end_time?: number
    page?: number
    page_size?: number
    start_time?: number
    type?: string
  },
  {
    code: number
    data: null | IYongHuZhangBianXiangQing[]
    msg: string
    pagination: { has_more: boolean; page: number; page_size: number }
    request_id: string
  }
>('userFundDetails', ({ page, page_size, type, start_time, end_time }) => ({
  url: `/v1/user/funddetails`,
  method: 'GET',
  params: {
    page,
    page_size,
    type,
    start_time,
    end_time,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 我的信息
 */
export const userInfo = createRequest<
  undefined,
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('userInfo', () => ({ url: `/v1/user/info`, method: 'GET', headers: { 'Service-Name': serviceName } }))

/**
 * 充值记录
 */
export const userRecharge = createRequest<
  {
    end_time?: string
    page?: number
    page_size?: number
    start_time?: string
    status?: string
    symbol?: string
    txid?: string
  },
  { code: number; data: null | IChongZhiXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>('userRecharge', ({ page, page_size, txid, start_time, end_time, status, symbol }) => ({
  url: `/v1/user/recharge`,
  method: 'GET',
  params: {
    page,
    page_size,
    txid,
    start_time,
    end_time,
    status,
    symbol,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 充值记录详情
 */
export const userRechargeQuery = createRequest<
  {
    order_no?: string
  },
  { code: number; data: IChongZhiXiangQing; msg: string; request_id: string }
>('userRechargeQuery', ({ order_no }) => ({
  url: `/v1/user/recharge_query`,
  method: 'GET',
  params: {
    order_no,
  },
  headers: { 'Service-Name': serviceName },
}))

/**
 * 设置邮箱
 */
export const userSetEmail = createRequest<
  {
    requestBody?: { captcha: string; captcha_id: string; email: string }
  },
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('userSetEmail', ({ requestBody }) => ({
  url: `/v1/user/setemail`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 设置邮箱验证
 */
export const userSetEmailVerify = createRequest<
  {
    requestBody?: { email: string; password: string; password_confirm: string; unique_id: string; verify_code: string }
  },
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('userSetEmailVerify', ({ requestBody }) => ({
  url: `/v1/user/setemailverify`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * Telegram解除绑定
 */
export const userUnBindTelegram = createRequest<
  {
    requestBody?: {}
  },
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('userUnBindTelegram', ({ requestBody }) => ({
  url: `/v1/user/unbindtelegram`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 通过邮箱解绑Telegram账号
 */
export const userUnBindTelegramByEmail = createRequest<
  {
    requestBody?: { captcha: string; captcha_id: string }
  },
  { code: number; data?: null; msg: string; request_id: string }
>('userUnBindTelegramByEmail', ({ requestBody }) => ({
  url: `/v1/user/unbindtelegrambyemail`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 通过邮箱解绑Telegram账号验证
 */
export const userUnBindTelegramByEmailVerify = createRequest<
  {
    requestBody?: { email: string; unique_id: string; verify_code: string }
  },
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('userUnBindTelegramByEmailVerify', ({ requestBody }) => ({
  url: `/v1/user/unbindtelegrambyemailverify`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * Tron钱包解除绑定-Nonce
 */
export const userUnBindTronNonce = createRequest<
  {
    requestBody?: { address: string }
  },
  { code: number; data: { nonce: string }; msg: string; request_id: string }
>('userUnBindTronNonce', ({ requestBody }) => ({
  url: `/v1/user/unbindtronnonce`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * Tron钱包解除绑定
 */
export const userUnBindTronWallet = createRequest<
  {
    requestBody?: { address: string; fingerprint?: string; signature: string }
  },
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('userUnBindTronWallet', ({ requestBody }) => ({
  url: `/v1/user/unbindtronwallet`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 通过邮箱解绑钱包
 */
export const userUnBindTronWalletByEmail = createRequest<
  {
    requestBody?: { captcha: string; captcha_id: string }
  },
  { code: number; data?: null; msg: string; request_id: string }
>('userUnBindTronWalletByEmail', ({ requestBody }) => ({
  url: `/v1/user/unbindtronwalletbyemail`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 通过邮箱解绑钱包验证
 */
export const userUnBindTronWalletByEmailVerify = createRequest<
  {
    requestBody?: { email: string; unique_id: string; verify_code: string }
  },
  { code: number; data: IYongHuXinXiBenRen; msg: string; request_id: string }
>('userUnBindTronWalletByEmailVerify', ({ requestBody }) => ({
  url: `/v1/user/unbindtronwalletbyemailverify`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json', 'Service-Name': serviceName },
}))

/**
 * 投票列表
 */
export const witnessApi = createRequest<
  {
    trx_address?: string
  },
  { code: number; data: ITouPiaoLieBiao[]; msg: string; request_id: string }
>('witnessApi', ({ trx_address }) => ({
  url: `/v1/order/witness`,
  method: 'GET',
  params: {
    trx_address,
  },
  headers: { 'Service-Name': serviceName },
}))

export interface IApiXiangQing {
  create_time: number
  id: number
  key: string
  name: string
  qps: number
  quota: number
  used: number
}

export interface IBoChangQianBaoJiBenXinXi {
  acquired_delegated_balance_for_bandwidth: number
  acquired_delegated_balance_for_energy: number
  acquired_delegated_for_bandwidth: number
  acquired_delegated_for_energy: number
  address: string
  balance: number
  can_delegated_bandwidth: number
  can_delegated_bandwidth_max_size: number
  can_delegated_energy: number
  can_delegated_energy_max_size: number
  delegated_balance_for_bandwidth: number
  delegated_balance_for_energy: number
  delegated_for_bandwidth: number
  delegated_for_energy: number
  energy_limit: number
  energy_surplus: number
  energy_used: number
  free_net_limit: number
  free_net_surplus: number
  free_net_used: number
  frozen_bandwidth: number
  frozen_bandwidth_balance: number
  frozen_energy: number
  frozen_energy_balance: number
  net_limit: number
  net_surplus: number
  net_used: number
  total_assets: number
}

export interface IBuyTrxTransactionResp {
  active_account_fee: number
  buy_bandwidth_fee: number
  buy_energy_fee: number
  exchange_amount: number
  exchange_service_amount: number
  exchange_service_amount_ratio: number
  exchange_symbol: string
  exchange_trx_fee: number
  order_no: string
  pay_address: string
  pay_amount: number
  pay_expiration: number
  pay_result: string
  pay_status: number
  pay_symbol: string
  pay_to_address: string
  pay_tx_id: string
  pay_type: number
  receive_address: string
  signed_txn: ISignedTransaction
}

export interface IChengGong {
  code: number
  data: {}
  msg: string
  request_id: string
}

export interface IChongZhiXiangQing {
  amount: number
  amount_decimals: number
  create_time: number
  decimals: number
  from_address: string
  order_no: string
  pay_time: number
  remark: string
  status: number
  symbol: string
  to_address: string
  txid: string
}

export interface IDaJiaZaiZuDingDanXiangQing {
  create_time: number
  freeze_time: number
  frozen_tx_id: string
  owner_address: string
  receive_address: string
  rent_duration: number
  rent_expire_time: number
  rent_time_second: number
  resource_type: number
  resource_value: number
  status: number
  unfreeze_time: number
}

export interface IDaiLiDingDanYongJinXiangQing {
  action?: number
  buy_settle_amount: number
  buy_settle_ratio: number
  buy_settled: number
  buy_user_id: number
  create_time: number
  freeze_time: number
  order_no: string
  order_status: number
  pay_amount: number
  pay_time: number
  refund_amount: number
  sell_settle_amount: number
  sell_settle_ratio: number
  sell_settled: number
  sell_user_id: number
  service_amount: number
  settle_status: number
  settle_time: number
  telegram_chat_id: number
  telegram_chat_name: string
}

export interface IDaiLiGaiLan {
  today_buy_commissions: number
  today_buy_commissions_count: number
  today_commissions: number
  today_sell_commissions: number
  today_sell_commissions_count: number
  total_commissions: number
  yesterday_buy_commissions: number
  yesterday_buy_commissions_count: number
  yesterday_commissions: number
  yesterday_sell_commissions: number
  yesterday_sell_commissions_count: number
}

export interface IDaiLiShenQingXiangQing {
  apply_id: number
  channel: string
  contacts: string
  cooperation_content: string
  create_time: number
  delete_time: number
  handle_time: number
  status: number
}

export interface IDaiLiXiangQing {
  agent_id: number
  allow_customize_price: number
  announcement_cn: string
  announcement_en: string
  announcement_ru: string
  buy_increase_sun: number
  buy_settle_ratio: number
  domain: string
  role: number
  sell_settle_ratio: number
  settle_address: string
  show_last_trades: number
  site_name: string
  subdomain: string
  telegram_bot_enable: number
  telegram_bot_name: string
  telegram_bot_token: string
  telegram_bot_username: string
  telegram_cs_username: string
  template_code: string
  total_buy_order_num: number
  total_buy_settle_amount: number
  total_sell_order_num: number
  total_sell_settle_amount: number
  total_settle_amount: number
  user_id: number
}

export interface IDingDanTiJiaoQingQiu {
  auto_to_market?: number
  order_type: number
  pay_address: string
  pay_amount: number
  pay_symbol: string
  price_in_sun?: number
  receive_address: string
  rent_duration?: number
  rent_time_second?: number
  rent_time_unit: string
  resource_type: number
  resource_value: number
}

export interface IDongJieDingDan {
  expire_time: number
  from: string
  frozen_balance: number
  to: string
  types: number
}

export interface IDongJieDingDanXin {
  expireTime: number
  frozenBalance: number
  hash: string
  ownerAddress: string
  receiverAddress: string
  resource: string
  resourceValue: string
  timestamp: number
}

export interface IDuiHuanDingDanXiangQing {
  contract_address: string
  create_time: number
  exchange_amount: number
  exchange_pay_status: number
  exchange_rate: number
  exchange_symbol: string
  exchange_tx_id: string
  finish_time: number
  order_no: string
  owner_address: string
  pay_address: string
  pay_amount: number
  pay_status: number
  pay_symbol: string
  pay_time: number
  pay_tx_id: string
  pay_type: number
  receive_address: string
  status: number
}

export interface IEstimate {
  have_token_energy_fee: number
  have_token_energy_used: number
  not_have_token_energy_fee: number
  not_have_token_energy_used: number
}

export interface IEstimateTransactionDetail {
  bandwidth_fee: number
  bandwidth_used: number
  energy_fee: number
  energy_used: number
}

export interface IExportTaskDetail {
  build_duration: number
  build_time: number
  conditions: string
  create_time: number
  delete_time: number
  down_count: number
  error_msg: string
  error_status: number
  file_ext: string
  file_name: string
  file_path: string
  file_size: number
  finish_time: number
  hash: string
  mime_type: string
  scene: number
  status: number
  task_id: number
  user_id: number
}

export interface IJianKongDiZhiXiangQing {
  balance: number
  chain: string
  create_time: number
  notify_url: string
  receive_amount_limit: number
  receive_in: number
  receive_notify_url: number
  receive_out: number
  receive_telegram: number
  receive_token: number
  receive_usdt: number
  remark: string
  status: number
  usdt_balance: number
  wallet_address: string
}

export interface IJianKongJiaoYiMingXi {
  amount: number
  amount_decimals: number
  chain: string
  contract_address: string
  create_time: number
  decimals: number
  fee: number
  from_address: string
  monitor_address: string
  notify_telegram_status: number
  notify_telegram_time: number
  notify_url_finish_time: number
  notify_url_status: number
  notify_url_time: number
  pay_time: number
  remark: string
  symbol: string
  to_address: string
  trx_balance: number
  tx_hash: string
  usdt_balance: number
}

export interface IJieTiJiaGeXiangQing {
  extra_add_day: number
  max: number
  min: number
  sun: number
  sun_1d: number
  sun_1h: number
  sun_2d: number
  sun_3h: number
}

export interface ILadder {
  extra_add_day: number
  max: number
  min: number
  sun: number
  sun_1d: number
  sun_1h: number
  sun_2d: number
  sun_3h: number
}

export interface ILastTradeDetail {
  create_time: number
  freeze_time: number
  frozen_balance: number
  frozen_tx_id: string
  order_no: string
  price_in_sun: number
  receive_address: string
  rent_time_second: number
  resource_type: number
  resource_value: number
  settle_amount: number
}

export interface IMaiJiaDingDanYueBaoBiaoXiangQing {
  bandwidth_value: number
  energy_value: number
  frozen_amount: number
  modify_time: number
  month: number
  order_amount: number
  order_bandwidth_num: number
  order_energy_num: number
  reward_amount: number
  settle_amount: number
  year: number
}

export interface IMaiJiaGaiLanTongJi {
  last_week_power_reward: number
  this_week_power_reward: number
  today_freed_balance: number
  today_freed_bandwidth: number
  today_freed_energy: number
  today_frozen_balance: number
  today_order_bandwidth: number
  today_order_bandwidth_num: number
  today_order_energy: number
  today_order_energy_num: number
  today_power_reward: number
  today_settle_amount: number
  tomorrow_freed_balance: number
  tomorrow_freed_bandwidth: number
  tomorrow_freed_energy: number
  total_order_waiting_settle_amount: number
  total_profit: number
  total_reward: number
  total_settle_amount: number
  yesterday_frozen_balance: number
  yesterday_order_bandwidth: number
  yesterday_order_bandwidth_num: number
  yesterday_order_energy: number
  yesterday_order_energy_num: number
  yesterday_power_reward: number
  yesterday_settle_amount: number
}

export interface IMaiJiaXiangQing {
  allow_delegate: number
  allow_freeze_v2: number
  allow_undelegate: number
  allow_unfreeze_v2: number
  allow_vote_witness: number
  allow_withdraw_balance: number
  auto_freeze_v2: number
  can_delegated_bandwidth: number
  can_delegated_bandwidth_max_size: number
  can_delegated_energy: number
  can_delegated_energy_max_size: number
  create_time: number
  delegated_balance_for_bandwidth: number
  delegated_balance_for_energy: number
  exclusive_agency: number
  freeze_time: number
  frozen_bandwidth: number
  frozen_bandwidth_balance: number
  frozen_energy: number
  frozen_energy_balance: number
  permission_id: number
  permission_name: string
  power_limit: number
  power_used: number
  remark?: string
  reward: number
  reward_time: number
  role: number
  seller_id: number
  settle_address: string
  settle_expected_ratio: number
  status: number
  take_freezing_amount?: number
  take_order: number
  take_order_amount: number
  take_order_bandwidth_min_sun: number
  take_order_energy_min_sun: number
  take_order_num: number
  take_order_resource: number
  take_order_time_type: number
  take_order_time_type_bandwidth: number
  take_settle_amount: number
  to_address: string
  total_assets: number
  total_reward: number
  trx_balance: number
  unfreeze_time: number
  update_run_time?: number
  vote_annualized_rate: number
  vote_time: number
  wallet_address: string
  withdraw_keep_amount: number
  withdraw_type: number
}

export interface INewsCommentDetail {
  comment_id: number
  content: string
  create_time: number
  device_brand: string
  device_type: string
  last_reply?: null | INewsCommentDetail[]
  level: number
  like_count: number
  news_id: number
  reply_count: number
  status: number
  user: null | {}
  user_id: number
}

export interface INewsDetail {
  alias: string
  allow_comment: number
  category: null | INewsMetaDetail[]
  comment_count: number
  content?: string
  covers: string
  create_time: number
  is_ad: number
  is_like: number
  is_original: number
  like_count: number
  news_id: number
  share_count: number
  stick: number
  summary: string
  tag: null | INewsMetaDetail[]
  title: string
  view_count: number
}

export interface INewsMetaDetail {
  alias: string
  count: number
  mid: number
  name: string
}

export interface IOrderCronDetail {
  amount_limit: number
  amount_used: number
  count_limit: number
  count_used: number
  create_time: number
  end_time: number
  limit_type: number
  order_resource_value: number
  order_type: number
  price_in_sun: number
  remark: string
  rent_time_second: number
  resource_threshold: number
  resource_type: number
  start_time: number
  status: number
  wallet_address: string
}

export interface IPagination {
  cursor?: string
  has_more: boolean
  page: number
  page_size: number
  total?: number
}

export interface IShouQuanCaoZuoMingXiJiaoYiMingXi {
  amount: number
  amount_decimals: number
  contract_address: string
  contract_type: number
  create_time: number
  decimals: number
  finish_time: number
  from_address: string
  handle_time: number
  order_no: string
  pay_time: number
  remark: string
  seller?: IMaiJiaXiangQing
  seller_id: number
  to_address: string
  transaction_id: number
  txid: string
  type: number
}

export interface ISignedTransaction {
  raw_data: {
    contract: { parameter?: { type_url: string; value: string }; type?: string }[]
    expiration: number
    ref_block_bytes: string
    ref_block_hash: string
    timestamp: number
  }
  raw_data_hex: string
  signature: string[]
  txID: string
  visible: boolean
}

export interface ISunConfig {
  service_amount: number
  service_amount_limit: number
  sun: number
  sun_1d: number
  sun_1h: number
  sun_2d: number
  sun_3h: number
}

export interface ITiBiXiangQing {
  amount: number
  audit_reason: string
  audit_time: number
  cash_status: number
  create_time: number
  fail_reason: string
  finish_time: number
  from_address: string
  handle_status: number
  handle_time: number
  order_no: string
  pay_time: number
  remark: string
  show_status: number
  symbol: string
  to_address: string
  tx_id: string
}

export interface ITouPiaoLieBiao {
  address: string
  annualizedRate: number
  my_votes: number
  name: string
  real_time_votes: number
}

export interface ITradeDetail {
  create_time: number
  expire_time: number
  frozen_balance: number
  frozen_resource_value: number
  frozen_tx_id: string
  is_lock: number
  is_split: number
  lock_period: number
  max_amount: number
  max_freeze: number
  max_payout: number
  min_amount: number
  min_freeze: number
  min_payout: number
  order_no: string
  price_in_sun: number
  receive_address: string
  rent_duration: number
  rent_time_second: number
  rent_time_unit: string
  resource_split_value: number
  resource_type: number
  resource_value: number
  status: number
}

export interface ITtPayOrderResp {
  expire_second: number
  out_trade_no: string
  payment_url: string
  prepay_id: string
}

export interface ITuiGuangMingXi {
  address: string
  create_time: number
  money: number
}

export interface ITuiGuangYongHuMingXi {
  account: string
  commission: number
  email: string
  is_seller: number
  last_login_time: number
  last_time: number
  reg_time: number
  status: number
  telegram_first_name: string
  telegram_last_name: string
  telegram_username: string
  trx_address: string
  unique_id: string
  user_id: number
}

export interface IUsableRent {
  resource_val: number
  today_energy: number
  trx_money: number
  yesterday_energy: number
}

export interface IUserLiteDetail {
  lang: string
  last_time: number
  reg_time: number
  telegram_first_name: string
  telegram_last_name: string
  trx_money: number
  unique_id: string
}

export interface IUserLiteDetailResp {
  lang: string
  last_time: number
  reg_time: number
  telegram_first_name: string
  telegram_last_name: string
  this_month_order_amount: number
  this_month_order_num: number
  this_month_rent_energy: number
  today_order_amount: number
  today_order_num: number
  today_rent_energy: number
  trx_money: number
  unique_id: string
  yesterday_order_amount: number
  yesterday_order_num: number
  yesterday_rent_energy: number
}

export interface IUserSessionDetail {
  browser_languages: string
  browser_name: string
  browser_platform: string
  browser_vendor: string
  browser_vendor_flavors: string
  browser_version: string
  browser_visitor_id: string
  create_time: number
  data: string
  device: string
  expire_time: number
  is_me: number
  last_device: string
  last_ip: string
  last_ip_address: string
  last_time: number
  login_device: string
  login_ip: string
  login_ip_address: string
  modify_time: number
  os: string
  os_version: string
  platform: string
  screen_resolution: string
  session_id: string
  timezone: string
  user_agent: string
  user_id: number
}

export interface IWeiTuoDingDanV2 {
  balance: number
  expireTime: number
  lockBalance: number
  lockResourceValue: number
  operationTime: number
  ownerAddress: string
  receiverAddress: string
  resource: number
  resourceValue: number
}

export interface IWenZhang {
  article_id: number
  content: string
  content_en: string
  create_time: number
  image: string
  image_en: string
  is_popup: number
  lang: string
  modify_time: number
  show_position: string
  summary: string
  summary_en: string
  title: string
  title_en: string
  type: number
  url: string
  url_en: string
}

export interface IYongHuBaoBiao {
  activity_amount: number
  all_return_amount: number
  bet_amount: number
  bet_count: number
  cash_amount: number
  commission_amount: number
  money: number
  profit_amount: number
  symbol: string
  transfer_amount: number
  win_amount: number
}

export interface IYongHuXinXiBenRen {
  account: string
  agent?: IDaiLiXiangQing
  email: string
  enable_authenticator: number
  energy_balance?: number
  invite_url: string
  is_agent: number
  is_seller: number
  last_login_time: number
  last_time: number
  nickname?: string
  reg_time: number
  seller?: IMaiJiaXiangQing
  status: number
  telegram_bot_used: number
  telegram_user_id: number
  telegram_username: string
  trx_address: string
  trx_balance: number
  trx_money: number
  user_id: number
  wallet?: IYongHuZhuanShuQianBao
}

export interface IYongHuZhangBianXiangQing {
  amount: number
  create_time: number
  log_id: number
  order_no: string
  remark: string
  tx_id: string
  type: number
}

export interface IYongHuZhuanShuQianBao {
  balance: number
  usdt_balance: number
  wallet_address: string
}

export interface IZuYongDingDanXiangQing {
  agent?: IDaiLiDingDanYongJinXiangQing
  cancel_tx_id?: string
  create_time?: number
  expire_time?: number
  freeze_time: number
  frozen_balance?: number
  frozen_resource_value: number
  frozen_status?: number
  frozen_tx_id: string
  is_lock: number
  is_split: number
  lock_period?: number
  max_amount: number
  max_freeze: number
  max_payout: number
  min_amount: number
  min_freeze: number
  min_payout: number
  modify_time?: number
  order_id?: number
  order_no: string
  order_num: number
  order_type?: number
  owner_address?: string
  pay_action?: number
  pay_address: string
  pay_amount: number
  pay_expiration?: number
  pay_service_amount?: number
  pay_status: number
  pay_symbol: string
  pay_time: number
  pay_tx_id: string
  price_in_sun: number
  promote_commissions?: number
  receive_address: string
  refund_amount?: number
  refund_time?: number
  refund_tx_id?: string
  rent_duration: number
  rent_expire_time: number
  rent_time_second: number
  resource_split_value: number
  resource_type: number
  resource_value: number
  status?: number
  unfreeze_time: number
  user_id?: number
}
